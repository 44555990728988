import React from "react";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import useWindowDimensions from '../../utils/windowDimention';

const MoreFooter = (props) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <div className={classes.mainfooter} style={{top: height - (isMobile ? 80 : 200)}}>
      <div className={classes.morefooter}>
        { props.leftIcon && <div onClick={props.onLeftIconPress}>
            <img src={props.leftIcon} className={classes.leftfootericn} />
          </div>
        }
        { props.rightIcon && <div onClick={props.onRightIconPress}>
            <img src={props.rightIcon} className={classes.rightfootericn} />
          </div>
        }
        { props.centerIcon && <div onClick={props.onCenterIconPress}>
            <img src={props.centerIcon} className={classes.centerfootericn} />
          </div> 
        }
      </div>
    </div>
  );
};

export default MoreFooter;
