import "./styles.css";
import { maxGuesses, seed, urlParam } from "../../util";
import NumberGame from "./components/mainBoard";
import { useEffect, useState } from "react";
import InnerPageHeader from "../../Components/InnerPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { addFamer, addToJoin, completeR2Win, completeR2Win2, getWinEnggR2, getWinEnggR2New, getWinEnggR2Sequence } from "../../services/ApiService";
import { ids } from "../../constants";
import useStyles from './style';
import { isIOS, isMobile } from "react-device-detect";
import Coin from '../../assets/images/header-images/Coin.png';
import { Hidden } from "@material-ui/core";
import Postloader from "../Postloader";

enum GameState {
  Playing,
  Won,
  Lost,
}

function App() {
  const navigate = useNavigate();
  const props = useLocation().state;
  const [gameState, setGameState] = useState(GameState.Playing);
  const [hofWinData, setHofWinData] = useState({
    amount: 0,
    product: null
  });
  // const [hofWinData, setHofWinData] = useState({
  //   amount: 0,
  //   product: {
  //     "id": 20021,
  //     "name": "1 EGLD",
  //     "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648799030951.png"
  //   }
  // })
  const [r2, setR2] = useState({amount: -1});
  const [sequence, setSequence] = useState(1);
  const [maxTile, setMaxTile] = useState(0);
  const [winComparisonId, setWinComparisonId] = useState();
  const [firstTime, setFirstTime] = useState(true);

  const onQuitPress = () => {
    notifyLost(maxTile);
  }

  const classes = useStyles();

  // useEffect(() => {
  //   fetchR2();
  //   fetchSequence();
  // }, [])

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  // const fetchSequence = async () => {
  //   try {
  //     const response = await getWinEnggR2Sequence({engagementId: props.engagementData.newId});
  //     //  console.log(`response=${JSON.stringify(response)}`)
  //     if (response && response.data.sequence) {
  //       setSequence(response.data.sequence + 1);
  //     }
  //   } catch (e) {
  //     console.log(`error=${e}`);
  //   }
  // };

  const fetchR2 = async (maxTile: number) => {
    try {
      const response = await getWinEnggR2New({engagementId: props.engagementData.newId, score: maxTile, word: `${maxTile}`});
      //  console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setR2(response.data);
        setWinComparisonId(response.data.winComparisonId);
      }
    } catch (e) {
      console.log(`error=${e}`);
    }
  };

  const _addFamer = async () => {
    try {
      await addFamer({ engagementId: props.engagementData.newId })
    } catch (e) {
      console.log(e);
    }
  };

  const uploadJoin = async (isWon) => {
    try {
      const joinData = [
        {
          engagement_id: props.engagementData.newId,
          engagement_title: props.engagementData.title,
          record_time: new Date().toISOString().slice(0, -5),
          win_criteria: isWon ? 0 : 1,
          amount: 0,
          tickets: 0,
          difficulty_level: props.userData.difficultyClassId,
          type: 3 //words - 1; topic - 2; numbers - 3; 
        },
      ];
      await addToJoin(joinData);
      // console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  }

  const processWin = async () => {
    // console.log(`r2=${JSON.stringify(r2)}, guesses=${guesses}`)
    // let index = -1;
    // switch (maxTile) {
    //   case 2048:
    //     index = 0;
    //     break;
    //   case 1024:
    //     index = 1;
    //     break;
    //   case 512:
    //     index = 2;
    //     break;
    //   case 256:
    //     index = 3;
    //     break;
    //   case 128:
    //     index = 4;
    //     break;
    // }
    // if (index >= 0) {
    //   const wonData = r2[index]; 
    //   setHofWinData({
    //     amount:  wonData.amount,
    //     product: wonData.product
    //   })
    //   try {
    //     await completeR2Win({
    //       engagementId: props.engagementData.newId,
    //       win: index + 1, //+1 cause 0-based array
    //       sequence,
    //       word: maxTile,
    //       txnType: ids.txnTypeRondleNumberWin
    //   });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    try {
      await completeR2Win2({
        winComparisonId: winComparisonId,
        txnType: ids.txnTypeRondleNumberWin
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (winComparisonId) {
      processWin()
    }
  }, [winComparisonId])

  useEffect(() => {
    const wonData = r2;
    console.log(`wonData=${JSON.stringify(wonData)}`)
    setHofWinData({
      amount:  wonData.amount,
      product: wonData.product
    })
  }, [r2])

  useEffect(() => {
    fetchR2(maxTile);
  }, [firstTime])

  const notifyWon = (maxTile: number) => {
    if (gameState === GameState.Playing && firstTime) {
      setFirstTime(false);
      setMaxTile(maxTile); 
      // processWin(maxTile);
      uploadJoin(true);
      if (maxTile >= 2048) { //only guessed no lives used will be added to famer
        _addFamer();
      }
      setGameState(GameState.Won);   
    }
  }

  const notifyLost = (maxTile: number) => {
    if (gameState === GameState.Playing) {
      if (maxTile >= 128) { //consider win
        notifyWon(maxTile);
      }
      else {
        uploadJoin(false);
        setMaxTile(maxTile);
        setGameState(GameState.Lost);
      }
    }
  }

  return (
    <div className={"Number-container"} style={{  overscrollBehaviorY: 'none', overflowY: 'hidden',
      backgroundImage: `url(${(JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0].background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundBlendMode: 'luminosity'}}
    >
      <NumberGame 
        prizes={(JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0].prizes}
        notifyWon={(maxTile: number) => notifyWon(maxTile)}
        notifyLost={(maxTile: number) => notifyLost(maxTile)}
        setMaxTile={(maxTile: number) => setMaxTile(maxTile)}
      />
      <Postloader gameState={gameState} hofWinData={hofWinData} guessLeft={0} correctText='YOUR SCORE:' 
        word={maxTile} 
        avatarBig={props.engagementData.source.avatarBig} postloaderImageBig={props.engagementData.postloaderImageBig}  showHeart={false}
        sourceId={props.engagementData.source.id} engagementId={props.engagementData.newId}
        onRepeatPress={() => {
          localStorage.setItem("autoJoin", "1");
          navigate(-1);
        }}
        lessonData={props.lessonData}
      />
      {/* {gameState === GameState.Won && (
        <div style={styles.prizeContainer}>
          <img src={props.engagementData.source.avatarBig} className={classes.logo} />
          <span className={classes.correctAnswer}>YOUR SCORE:</span>
          <span className={classes.answer}>{maxTile}</span>
          <span className={classes.scratchWinMuch}>You won</span>
          {hofWinData.amount > 0 && (
            <div>
              <div className={classes.coinImageText}>
                <div className={classes.coinImageTextRow}>
                  <img
                    src={Coin}
                    className={classes.coinYellowImage}
                  />
                  <h2 className={classes.coinText}>
                    {hofWinData.amount}
                  </h2>
                </div>
              </div>
              <div className={classes.availableProtectCoin}>
              </div>
              <div className={classes.scoreCounterRow}>
              </div>
            </div>
          )}
          {hofWinData.product && (
            <div>
              <img
                src={hofWinData.product.imageBig}
                alt="bengga coin img"
                className={classes.scratchWinProductImage}
              />
            </div>
          )}
          <div style={styles.buttonContainer}>
            <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
              BACK
            </button>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )}
      {gameState === GameState.Lost && (
        <div style={styles.prizeContainer}>
          <img src={props.engagementData.source.avatarBig} className={classes.logo} />
          <span className={classes.correctAnswer}>YOUR SCORE:</span>
          <span className={classes.answer}>{maxTile}</span>
          <span className={classes.scratchWinMuch}>Try again</span>
          <div>
            <img
              src={props.engagementData.postloaderImageBig}
              alt="bengga coin img"
              className={classes.lostImage}
            />
          </div>

          <div style={styles.buttonContainer}>
            <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
              BACK
            </button>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )} */}
      <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
        <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} hasQuitBtn={true} onQuitPress={onQuitPress} title='' style={{position: 'absolute'}}/>
      </div>
    </div>
  );
}

export default App;
