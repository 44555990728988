import { QuestionType } from './'

const question_easy: QuestionType[] = [
  {
    questionId: 100001,
    question: "Another name for High Blood Pressure is",
    correct_answer: "Hypertension",
    incorrect_answers: [
      "Stroke",
      "Aneurysm",
      "Cirrhosis"
    ]
  },
{
    questionId: 100002,
    question: "The normal blood pressure level is",
    correct_answer: "120/80",
    incorrect_answers: [
      "160/100",
      "140/90",
      "100/60"
    ]
  },
{
    questionId: 100003,
    question: "An effective lifestyle change to reduce blood pressure is",
    correct_answer: "All of the given",
    incorrect_answers: [
      "Lower salt intake",
      "Lose weight if overweight",
      "Exercise regularly"
    ]
  },
{
    questionId: 100004,
    question: "Taking the right medicines for hypertension can prolong life by",
    correct_answer: "7 to 10 yrs.",
    incorrect_answers: [
      "8 to 12 yrs.",
      "a decade",
      "two decades"
    ]
  },
{
    questionId: 100005,
    question: "All are medicines for High Blood Pressure except",
    correct_answer: "Metformin",
    incorrect_answers: [
      "Accupril",
      "Metoprolol",
      "Amlodipine"
    ]
  },
  {
    questionId: 100006,
    question: "Hypertension is another name for",
    correct_answer: "High Blood Pressure",
    incorrect_answers: [
      "Stroke",
      "Aneurysm",
      "Cirrhosis"
    ]
  },
{
    questionId: 100007,
    question: "90/60 is classified as a",
    correct_answer: "low blood pressure",
    incorrect_answers: [
      "high blood pressure",
      "normal blood pressure",
      "none of the given"
    ]
  },
{
    questionId: 100008,
    question: "All are effective lifestyle changes to reduce hypertension except",
    correct_answer: "None of the given",
    incorrect_answers: [
      "Lower salt intake",
      "Lose weight if overweight",
      "Exercise regularly"
    ]
  },
{
    questionId: 100009,
    question: "Taking the right medicines for hypertension can lead to",
    correct_answer: "prolong life",
    incorrect_answers: [
      "stroke",
      "shorter life",
      "heart attack"
    ]
  },
{
    questionId: 100010,
    question: "Accupril, Metoprolol, Amlodipine are all medicines for",
    correct_answer: "Hypertension",
    incorrect_answers: [
      "Diabetes",
      "Diarrhea",
      "Allergy"
    ]
  },
{
    questionId: 200001,
    question: "Ano ang symptom ng me sakit na Diabetes?",
    correct_answer: "All of the given",
    incorrect_answers: [
      "Ihi ng ihi",
      "Laging gutom",
      "Sugat di gumagaling"
    ]
  },
{
    questionId: 200002,
    question: "Sa Fasting Blood Sugar test for Diabetes, aling result ang Mild Diabetes?",
    correct_answer: "100 to 125 mg/dl",
    incorrect_answers: [
      "99 & below mg/dl",
      "126 & above mg/dl",
      "None of the given"
    ]
  },
{
    questionId: 200003,
    question: "Alin ang bawal kainin pag meron kang Diabetes?",
    correct_answer: "Chocolates",
    incorrect_answers: [
      "Vegetables",
      "Brown Rice",
      "Wheat Bread"
    ]
  },
{
    questionId: 200004,
    question: "Alin sa mga sumusunod ang mabisang  gamot para sa Diabetes?",
    correct_answer: "Insulin",
    incorrect_answers: [
      "Diatabs",
      "Accupril",
      "Amlodipine"
    ]
  },
{
    questionId: 200005,
    question: "Anong inumin ang pinaka safe para sa Diabetes?",
    correct_answer: "Tubig",
    incorrect_answers: [
      "Soda",
      "Softdrinks",
      "Coffee"
    ]
  }, {
    questionId: 200006,
    question: "Ano ang isang symptom ng me sakit na Diabetes?",
    correct_answer: "Sugat di gumagaling",
    incorrect_answers: [
      "Busog lagi",
      "Tawa ng tawa",
      "Masakit tenga"
    ]
  },
{
    questionId: 200007,
    question: "Sa Fasting Blood Sugar test for Diabetes, ang 100 to 125 mg/dl result ay",
    correct_answer: "Mild Diabetes",
    incorrect_answers: [
      "Normal",
      "Severe Diabetes",
      "None of the given"
    ]
  },
{
    questionId: 200008,
    question: "Alin ang pwedeng kainin pag meron kang Diabetes?",
    correct_answer: "Wheat Bread",
    incorrect_answers: [
      "Chocolates",
      "Cakes",
      "None of the given"
    ]
  },
{
    questionId: 200009,
    question: "Ang Insulin ay isang mabisang  gamot sa sakit na",
    correct_answer: "Diabetes",
    incorrect_answers: [
      "Hypertension",
      "Diarrhea",
      "Aneurysm"
    ]
  },
{
    questionId: 200010,
    question: "Lahat ay bawal inumin ng me Diabetes maliban sa",
    correct_answer: "Tubig",
    incorrect_answers: [
      "Soda",
      "Softdrinks",
      "Coffee w/ whipped cream"
    ]
  }];

export default question_easy;