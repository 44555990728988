import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '480px',
    width: '100%',
    padding: '0 20px',
    minHeight: '100vh',
    height: '100%',
    position: 'relative',
    background: 'white',
    margin: '0 auto'
  },
  scratchWinContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 40px)',
    height: 'auto'
  },
  scratchWinSmallImageBox: {
    border: '2px solid #379ea3',
    borderRadius: '100px',
    width: '70px',
    height: '70px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px auto 0'
  },
  coinImageText: {
    marginTop: '30px'
  },
  coinImageTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px'
  },
  coinYellowImage: {
    width: '35px',
    height: '35px',
    objectFit: 'contain',
    marginRight: '10px'
  },
  coinText: {
    color: '#dc2828',
    fontSize: '42px',
    margin: 0
  },
  coinBlueImage: {
    width: '80px',
    height: '15px',
    objectFit: 'contain'
  },
  availableProtectCoin: {
    textAlign: 'center',
    margin: '50px 0'
  },
  availableProtectCoinText: {
    color: '#dc2828',
    fontSize: '12px',
    fontWeight: 500,
    margin: 0
  },
  scoreCounterContainer: {
    alignItems: 'center'
  },
  scoreCounterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '50px'
  },
  scoreCounterLeftText: {
    color: '#dc2828',
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '10px'
  },
  level5Image: {
    width: '120px',
    height: '120px',
    display: 'block',
    margin: '0 auto'
  },
  scratchLoseMuch: {
    color: '#dc2828',
    fontSize: '24px',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'canaroMedium',
    marginTop: '100px',
  },
  scratchWinMuchText: {
    color: '#dc2828',
    fontSize: '17px',
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'canaroBook',
    marginTop: '30px',
  },
  scratchSubText: {
    color: '#dc2828',
    fontSize: '13px',
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'canaroBook',
    marginTop: '5px',
  },
  
  correctAnswer: {
    color: '#00acb8',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'canaroMedium',
    marginTop: '35px',
  },
  answer: {
    color: '#404040',
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'canaroMedium',
    marginTop: '10px',
  },
  scratchWinMuch: {
    color: '#dc2828',
    fontSize: '40px',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'canaroMedium',
    marginTop: '40px',
  },
  logo: {
    border: '2px solid #379ea3',
    borderRadius: '40px',
    width: '80px',
    height: '80px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '60px auto 0',
    padding: '10px'
  },
  scratchWinProductImage: {
    marginTop: '-20px',
    width: 200,
    height: 200,
    objectFit: 'contain'
  },
  lostImage: {
    marginTop: '20px',
    width: 300,
    height: 300,
    objectFit: 'contain'
  },
  scratchWinCoinImage: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  loseImg: {
    width: '180px',
    height: '245px',
    // width: '405px',
    // height: '552px',
    objectFit: 'contain',
    margin: '70px auto 0'
  },
  productText: {
    color: '#dc2828',
    fontSize: '20px',
    marginBottom: 20,
    paddingBottom: 10,
    textAlign: 'center'
  },
  youdidit: {
    color: '#dc2828',
    fontSize: '40px',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'canaroMedium',
    marginTop: '40px',
    marginBottom: '40px'
  },
}));
