import { Navigate, Outlet } from "react-router-dom";
import LoginPage from "./pages/Login/index";
import HomePage from "./pages/HomePage/index";
import { routes } from "../src/constants";
import Word from './pages/Words';
import MaiarPage from "./Components/MaiarPage";
import Tips from './pages/Tips';
import History from "./pages/History";
import Collection from "./pages/Collection";
import Profile from "./pages/Profile";
import Notification from "./pages/Notification";
import Famers from "./pages/Famers";
import Topic from "./pages/Topics";
import CollectionDetails from "./pages/Collection/Details";
import Number from './pages/Numbers';
import Leaderboard from "./pages/Leaderboard";
import MultipleChoice from "./pages/MultipleChoice";
import Lesson from "./pages/Lesson";
import LessonDetails from "./pages/Lesson/LessonDetails";
import Steal from './pages/Steal';
import PinoyHenyo from './pages/PinoyHenyo';
import PinoyHenyo2 from './pages/PinoyHenyo2';
import PinoyHenyo3 from './pages/PinoyHenyo3';
import SlotMachine from './pages/SlotMachine';
import Lotto from "./pages/Lotto";
import FunTypeRunner from './pages/FunTypeRunner';
import Winners from "./pages/History/Winners";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <Outlet /> : <Navigate to={routes.home} />,
    children: [
      { path: routes.login, element: <LoginPage /> },

      { path: routes.homepage, element: <Navigate to={routes.login} /> },
      // { path: routes.unlock, element: <UnlockRoute /> },
      { path: routes.maiar, element: <MaiarPage /> },
    ],
  },
  {
    path: routes.homepage, element: user ? <Outlet /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.home, element: <HomePage /> },
      { path: routes.word, element: <Word /> }, 
      { path: routes.show, element: <Tips /> },
      { path: routes.history, element: <History /> },
      { path: routes.collection, element: <Collection /> },
      { path: routes.collectionDetails, element: <CollectionDetails /> },
      { path: routes.profile, element: <Profile /> },
      { path: routes.notification, element: <Notification /> },
      { path: routes.famers, element: <Famers /> },
      { path: routes.topic, element: <Topic /> },
      { path: routes.topic2, element: <MultipleChoice /> },
      { path: routes.number, element: <Number /> },
      { path: routes.leaders, element: <Leaderboard /> },
      { path: routes.lesson, element: <Lesson /> },
      { path: routes.lessonDetails, element: <LessonDetails /> },
      { path: routes.steal, element: <Steal /> },
      { path: routes.henyo, element: <PinoyHenyo /> },
      { path: routes.henyo2, element: <PinoyHenyo2 /> },
      { path: routes.henyo3, element: <PinoyHenyo3 /> },
      { path: routes.lotto, element: <Lotto /> },
      { path: routes.slot, element: <SlotMachine /> },
      { path: routes.funtyperunner, element: <FunTypeRunner /> },
      { path: routes.winners, element: <Winners /> },
    ],
  },
];

const elrondRoutes = [
  {
    path: routes.home,
    title: 'Home',
    component: HomePage
  },
  {
    path: routes.login,
    title: 'Login',
    component: LoginPage,
    authenticatedRoute: true
  }
];

export default getRoutes;
